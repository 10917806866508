<template>
  <div id="dashboard-lom-president">
    <v-container fluid tag="section">
      <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
        <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
      </div>
      <v-container>
        <v-row wrap>
          <v-col cols="12" md="12" align="center">
            <v-btn
              elevation="30"
              shaped
              tile
              small
              color="#9370d8"
              class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
              @click.prevent="pageData"
            >
              Refresh
            </v-btn>
          </v-col>
        </v-row>
        <v-col cols="12" sm="6" md="3" lg="3">
          <label>
            <h6><span class="text-danger">*</span> Year</h6>
          </label>
          <v-autocomplete
            :reduce="(option) => option.value"
            :items="JciYearCodeOptions"
            :rules="JciYearCodeRules"
            v-model="JciYearCode"
            dense
            clearable
            required
            outlined
          ></v-autocomplete>
        </v-col>
      </v-container>
      <v-container v-if="StartupLoadingFlag">
        <v-row wrap>
          <v-col cols="12" md="4" v-for="n in 6" :key="n">
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="!LoadingFlag">
        <v-row wrap v-if="PersonalDetailsFlag">
          <v-col cols="12" md="8" align="center">
            <v-card
              :color="rows.TableStyle.Color1"
              :style="rows.TableStyle.Style1"
            >
              <v-card-title>
                <h3>National President details</h3>
              </v-card-title>
              <v-card-subtitle>
                <h5 class="text-left">
                  {{ rows.AreaName }} - {{ rows.ZoneName }} -
                  {{ rows.RegionName }} - {{ rows.LomName }}
                </h5>
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-text>
                <v-row wrap>
                  <v-col cols="12" md="3" align="center">
                    <v-img
                      contain
                      max-height="200"
                      :src="rows.ProfilePic"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" md="9" align="center">
                    <b-table
                      striped
                      hover
                      :items="rows.MemberDetails"
                      class="president-details-table"
                      thead-class="d-none"
                    ></b-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <router-link to="/my-profile">
                  <v-btn
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#fc7e50"
                    class="font-size-h6 mr-3 white--text"
                  >
                    Edit Profile
                  </v-btn>
                </router-link>
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  color="#9638b0"
                  class="font-size-h6 mr-3 white--text"
                  @click.prevent="LogoutUser"
                >
                  Logout
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" align="center">
            <v-card
              :color="rows.TableStyle.Color2"
              :style="rows.TableStyle.Style2"
            >
              <v-card-title><h3>Quick Links</h3></v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row wrap>
                  <v-col cols="12" align="center">
                    <!-- items-per-page.sync="6"  -->
                    <v-data-iterator
                      :items="rows.QuickLinks"
                      :items-per-page="-1"
                      hide-default-footer
                    >
                      <template v-slot:header> </template>

                      <template v-slot:default="props">
                        <v-row>
                          <v-col
                            v-for="item in props.items"
                            :key="item.LinkId"
                            cols="12"
                          >
                            <router-link :to="item.LinkUrl">
                              <v-btn
                                elevation="30"
                                shaped
                                tile
                                small
                                :color="item.LinkColor"
                                :class="item.LinkClass"
                                :style="item.LinkStyle"
                              >
                                {{ item.LinkTxt }}
                              </v-btn>
                            </router-link>
                          </v-col>
                        </v-row>
                      </template>
                    </v-data-iterator>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col cols="12" md="8" align="center" v-if="GrowthStatusTableFlag">
            <v-card
              :color="rows.TableStyle.Color3"
              :style="rows.TableStyle.Style3"
            >
              <v-card-title>
                <h3>Growth status</h3>
                <!-- <v-spacer></v-spacer>
                <router-link to="/members/subscription/payment/create">
                  <v-btn
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#fc7e50"
                    class="animate-button1"
                  >
                    Pay subscription
                  </v-btn>
                </router-link> -->
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row wrap>
                  <v-col cols="12" md="12" align="center">
                    <v-data-table
                      class="elevation-1"
                      v-model="selected1"
                      item-key="YearId"
                      :headers="rows.TableHeader1"
                      :items="rows.TableData1"
                      :show-select="false"
                      :items-per-page="50"
                      :search="search1"
                      :single-select="false"
                      :disable-sort="true"
                      :hide-default-footer="true"
                      :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50],
                      }"
                    >
                      <template v-slot:item.Growth="{ item }">
                        <v-chip :color="item.Color" draggable
                          ><b class="text-white"> {{ item.Growth }} </b></v-chip
                        >
                      </template>
                      <!-- <template v-slot:footer>
                        <v-row wrap>
                          <v-col cols="12" align="center">
                            <h3>Overall Growth: {{ rows.OverallGrowth }}</h3>
                          </v-col>
                        </v-row>
                      </template> -->
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="4" align="center" v-if="MrfTableFlag">
            <v-card
              :color="rows.TableStyle.Color4"
              :style="rows.TableStyle.Style4"
            >
              <v-card-title><h3>MRF Status (Coming soon)</h3></v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row wrap>
                  <v-col cols="12" align="center">
                    <!-- items-per-page.sync="6"  -->
                    <v-data-iterator
                      :items="rows.MrfTable"
                      :items-per-page="-1"
                      hide-default-footer
                    >
                      <template v-slot:header> </template>

                      <template v-slot:default="props">
                        <v-row>
                          <v-col
                            v-for="item in props.items"
                            :key="item.MonthNo"
                            cols="4"
                          >
                            <v-card>
                              <v-card-title>
                                {{ item.MonthTxt }}
                              </v-card-title>
                            </v-card>
                          </v-col>
                        </v-row>
                      </template>
                    </v-data-iterator>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <p></p>
        </v-row>
      </v-container>
      <v-container v-if="!LoadingFlag">
        <v-row wrap>
          <v-col cols="12" md="12" v-if="MembershipStatisticsChartFlag">
            <h6>
              <span class="text-danger">* </span> MFJ - Membership from 1st July
            </h6>
          </v-col>
          <v-col cols="12" md="12" align="center">
            <div :style="MembershipStatisticsStyle">
              <canvas id="MembershipStatisticsChart" style="height: 0"></canvas>
            </div>
          </v-col>

          <p></p>

          <v-col cols="12" md="12" align="center">
            <div :style="FoundationStyle">
              <canvas id="FoundationChart" style="height: 0"></canvas>
            </div>
          </v-col>

          <p></p>

          <v-col cols="12" md="12" align="center">
            <div :style="SeniorMemberStyle">
              <canvas id="SeniorMemberChart" style="height: 0"></canvas>
            </div>
          </v-col>

          <p></p>
        </v-row>

        <p></p>
      </v-container>
      <v-container v-if="!LoadingFlag && tableData1.length > 0">
        <v-row>
          <v-col>
            <h6>
              <span class="text-danger">* </span>MFJ - Membership from 1st July
            </h6>
          </v-col>
        </v-row>

        <v-row wrap v-if="excelOptions1.ExcelDownloadFlag == true">
          <v-col cols="12" md="12" align="center">
            <v-btn
              color="info"
              @click.prevent="
                generateExcel(
                  excelOptions1.ExcelData,
                  excelOptions1.ExcelFields,
                  excelOptions1.ExcelFileName,
                  'My Worksheet'
                )
              "
              class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
              >Download</v-btn
            >
          </v-col>
        </v-row>

        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <br />
            <v-data-table
              class="elevation-1"
              v-model="selected"
              :search="search"
              :item-key="tableOptions1.ItemKey"
              :single-select="tableOptions1.SingleSelectFlag"
              :headers="tableHeader1"
              :items="tableData1"
              :items-per-page="tableOptions1.ItemsPerPage"
              :footer-props="tableOptions1.FooterProps"
            >
              <template v-slot:item.ActiveStatusTxt="{ item }">
                <v-chip
                  :color="getActiveStatusColor(item.ActiveStatusTxt)"
                  draggable
                  dark
                  >{{ item.ActiveStatusTxt }}</v-chip
                >
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom v-if="item.EditFlag">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      icon
                      size="25px"
                      color="primary"
                      @click="editData(item, e)"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-file-document-edit</v-icon
                    >
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.DeleteFlag">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      icon
                      size="25px"
                      color="red"
                      @click="deleteConfirmAlert(item, e)"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <p></p>

        <dashboard-calendar :rows="rows" />

        <v-row wrap>
          <v-col cols="12" lg="12"> </v-col>
        </v-row>

        <p></p>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import DashboardCalendar from "@/view/pages/erp/dashboard/DashboardCalender.vue";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  mixins: [common],
  name: "DashboardDashboard",
  components: { DashboardCalendar },
  data() {
    return {
      LoadingFlag: false,
      StartupLoadingFlag: false,

      PersonalDetailsFlag: false,

      MembershipStatisticsStyle: "",
      MembershipStatisticsChart: "",
      MembershipStatisticsChartFlag: false,

      FoundationStyle: "",
      FoundationChart: "",

      SeniorMemberStyle: "",
      SeniorMemberChart: "",

      tableData1: [],
      tableOptions1: {},
      tableHeader1: [],

      JciYearCodeFlag: false,
      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],

      excelOptions1: {
        ExcelDownloadFlag: false,
        ExcelFileName: "",
        ExcelFields: [],
        ExcelData: [],
      },

      rows: {
        Chart1: {},
        ChartData1: {},
        TableBgColor1: "",
        TableBgColor2: "",
        TableBgColor3: "",
        TableBgColor4: "",
        TableBgColor5: "",
        ChartOptions1: {},
        Table1: {
          Title: "",
          Description: "",
        },
        TableHeader1: [],
        TableData1: [],
        Table2: {
          Title: "",
          Description: "",
        },
        TableHeader2: [],
        TableData2: [],
        EventsData: [],
      },
      ResultFlag: false,
      LoadingFlag: false,

      GrowthStatusTableFlag: false,
      MrfTableFlag: false,

      selected1: [],
      search1: "",

      itemsPerPage: 4,
      items: [
        {
          name: "Frozen Yogurt",
        },
        {
          name: "Ice cream sandwich",
        },
        {
          name: "Eclair",
        },
        {
          name: "Cupcake",
        },
      ],

      tableData3: [],
      tableColumns3: [],
      tableOptions3: [],
      selected: [],
      search: "",

      tabs: 0,
      tasks: {
        0: [
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: true,
          },
          {
            text: "Lines From Great Russian Literature? Or E-mails From My Boss?",
            value: false,
          },
          {
            text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: false,
          },
          {
            text: "Create 4 Invisible User Experiences you Never Knew About",
            value: true,
          },
        ],
        1: [
          {
            text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: true,
          },
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: false,
          },
        ],
        2: [
          {
            text: "Lines From Great Russian Literature? Or E-mails From My Boss?",
            value: false,
          },
          {
            text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: true,
          },
          {
            text: 'Sign contract for "What are conference organizers afraid of?"',
            value: true,
          },
        ],
      },
      list: {
        0: false,
        1: false,
        2: false,
      },

      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
    };
  },
  watch: {
    rows: function () {
      console.log("watch rows");
    },
    JciYearCode: function () {
      console.log("Watch JciYearCode called");
      this.pageData();
    },
  },
  mounted() {
    console.log("mounted");
    // this.$refs.calendar.checkChange();
  },
  created() {
    console.log("created");
    // this.getBarChartRecords();
  },
  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getJciYearCodeOptions();
    },
    pageData() {
      console.log("pageData called");
      this.getTableRecords();
      this.getBarChartRecords();
    },
    clearCharts() {
      // clear all charts

      if (this.MembershipStatisticsChart) {
        this.MembershipStatisticsChart.destroy();
      }

      if (this.FoundationChart) {
        this.FoundationChart.destroy();
      }

      if (this.SeniorMemberChart) {
        this.SeniorMemberChart.destroy();
      }
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 5,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var LomCode = 1;
      console.log("LomCode=" + LomCode);

      var JciYearCode = this.JciYearCode;
      console.log({ JciYearCode });

      if (LomCode != null) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/dashboard/user/lom-president";
        var upload = {
          UserInterface: 1,
          Year: JciYearCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // this.rows = {};
        this.StartupLoadingFlag = true;
        this.LoadingFlag = true;
        this.ResultFlag = false;
        this.PersonalDetailsFlag = false;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.StartupLoadingFlag = false;
            thisIns.LoadingFlag = false;
            thisIns.ResultFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.rows = records;
              thisIns.PersonalDetailsFlag = false;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.StartupLoadingFlag = false;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "LOM should not be empty.";
        console.log("error=" + message);
      }
    },
    getBarChartRecords() {
      console.log("getBarChartRecords called");

      var message = "";

      var JciYearCode = this.JciYearCode;
      console.log({ JciYearCode });
      if (JciYearCode > 0) {
        this.clearCharts();
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/dashboard/user/national-president";
        var upload = {
          UserInterface: 1,
          YearCode: this.JciYearCode,
          AreaCode: this.AreaCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.LoadingFlag = true;
        this.SearchFlag = true;
        this.MembershipStatisticsChartFlag = false;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;
            thisIns.SearchFlag = false;
            thisIns.MembershipStatisticsChartFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            var ExcelOptions = records.ExcelOptions;
            console.log({ ExcelOptions });
            thisIns.excelOptions1 = ExcelOptions;

            var TableOptions = records.TableOptions;
            console.log({ TableOptions });
            thisIns.tableOptions1 = TableOptions;

            var TableData = records.TableData;
            console.log({ TableData });
            thisIns.tableData1 = TableData;

            var TableHeader = records.TableHeader;
            console.log({ TableHeader });
            thisIns.tableHeader1 = TableHeader;

            if (flag == 1) {
              var tempFlag1 = records.MembershipStatisticsFlag;
              console.log({ tempFlag1 });

              var tempStyle1 = records.MembershipStatisticsStyle;
              console.log({ tempStyle1 });

              var tempChart1 = records.MembershipStatisticsChart;
              console.log({ tempChart1 });

              if (tempFlag1) {
                thisIns.MembershipStatisticsStyle = tempStyle1;

                var MembershipStatisticsChartData = tempChart1;

                thisIns.MembershipStatisticsChart = new Chart(
                  document.getElementById("MembershipStatisticsChart"),
                  {
                    type: tempChart1.chartType,
                    data: tempChart1.chartData,
                    options: tempChart1.chartOptions,
                  }
                );
              }

              var tempFlag2 = records.FoundationFlag;
              console.log({ tempFlag2 });

              var tempStyle2 = records.FoundationStyle;
              console.log({ tempStyle2 });

              var tempChart2 = records.FoundationChart;
              console.log({ tempChart2 });

              if (tempFlag2) {
                thisIns.FoundationStyle = tempStyle1;

                var FoundationChart = tempChart2;

                thisIns.FoundationChart = new Chart(
                  document.getElementById("FoundationChart"),
                  {
                    type: tempChart2.chartType,
                    data: tempChart2.chartData,
                    options: tempChart2.chartOptions,
                  }
                );
              }

              var tempFlag3 = records.SeniorMemberFlag;
              console.log({ tempFlag3 });

              var tempStyle3 = records.SeniorMemberStyle;
              console.log({ tempStyle3 });

              var tempChart3 = records.SeniorMemberChart;
              console.log({ tempChart3 });

              if (tempFlag3) {
                thisIns.FoundationStyle = tempStyle1;

                var SeniorMemberChart = tempChart3;

                thisIns.SeniorMemberChart = new Chart(
                  document.getElementById("SeniorMemberChart"),
                  {
                    type: tempChart3.chartType,
                    data: tempChart3.chartData,
                    options: tempChart3.chartOptions,
                  }
                );
              }
            }
          })
          .catch(function (error) {
            thisIns.SearchFlag = false;
            thisIns.LoadingFlag = false;
            console.log("error=" + error);
            thisIns.toast("error", error);
          });
      } else {
        message += "Year should not be empty";
        this.sweetAlert("error", message, false);
        this.MembershipStatisticsChartFlag = false;
        this.tableData1 = [];
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      console.log("updateRange called");
      console.log("start=" + JSON.stringify(start));
      console.log("end=" + JSON.stringify(end));

      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);
      // const eventCount = 1;
      console.log("eventCount=" + eventCount);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        var temp_event = {
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        };
        console.log("temp_event" + JSON.stringify(temp_event));

        events.push(temp_event);
      }

      this.events = [];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#dashboard-lom-president {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 15px;
  }
  .animate-button1 {
    display: inline-block;
    margin: 0 0.5rem;
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .president-details-table {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.01px;
    font-weight: 400;
    font-style: normal;
  }
  .lom-details-table {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.01px;
    font-weight: 400;
    font-style: normal;
  }
}
</style>